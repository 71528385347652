import React from "react"
import { Link } from "gatsby"
import Logo from "./logo"
import styled from "styled-components"

const Navbar = styled.div`
  padding-top: 1rem;
  overflow: hidden;

  @media screen and (max-width: 1099px) {
    text-align: center;
  }
`

const NavigationLogo = styled.div`
  float: left;
  display: block;
  width: 100px;

  @media screen and (max-width: 1099px) {
    float: none;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1em;
    text-align: center;
  }
`

const Navigation = styled.ul`
  float: right;
  color: #606060;

  @media screen and (max-width: 1099px) {
    width: 100%;
    height: auto;
    margin-bottom: 1em;
    text-align: center;
  }
`

const NavLink = styled(Link)`
  font-size: 0.7em;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 2px;
  line-height: 20.899999618530273px;
  list-style-type: disc;
  padding-bottom: 0px;
  padding-left: 11px;
  padding-right: 11px;
  padding-top: 15px;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;
  color: #606060;
  
  &:hover{
    color: #68A7A2;
    border-color: #68A7A2;
  }
`

export default function Header() {
  return (
    <div>
      <header>
        <Navbar>
          <NavigationLogo>
            <Link to="/">
              <Logo />
            </Link>
          </NavigationLogo>
          <Navigation>
            <NavLink to="https://www.dropbox.com/sh/t9rsnlarjs6c4p7/AAAhELA_UwtJr9KTUfQA77-pa?dl=0">Press Kit</NavLink>
            <NavLink to="https://apps.apple.com/us/app/meraki-to-do-list/id1526181709">Download</NavLink>
          </Navigation>
        </Navbar>
      </header>
    </div>
  )
}