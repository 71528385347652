import React from "react"
import Header from "../header/header"
import Footer from "../footer/footer"
import styled from "styled-components"
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html, body, div, h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
  }
`

const Site = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  max-width: 1100px;
  margin: auto;

  @media screen and (max-width: 1099px) {
    max-width: calc(100% - 80px);
  }
`

const SiteContent = styled.div`
  flex-grow:1;

  @media screen and (max-width: 1099px) {
    flex-grow: none;
  }
`

export default function Layout({ children }) {

  return (
    <Site>
      <GlobalStyle />
      <Header />
      <SiteContent>{children}</SiteContent>
      <Footer />
    </Site>
  );
}




