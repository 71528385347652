import React from "react"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"


export default function Logo() {
  return (
    <StaticQuery
      query={graphql`
        query LogoQuery {
          file(name: { eq: "logo-name" }, extension: { eq: "png" }) {
            childImageSharp {
              fluid(maxWidth: 400, pngQuality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
        <Img fluid={data.file.childImageSharp.fluid} alt="logo" />
      )}
    />
  )
}
