import React from "react"
import styled from "styled-components"
import { StaticQuery, graphql, Link } from "gatsby"

const Copyright = styled.p`
  float: left;
  color: #606060;
  padding-top: 10px;
  padding-bottom: 5px;
  font-size: 0.75em;
  vertical-align: baseline;
  display: inline-block;
`

const Socials = styled.div`
  float: right;
  overflow: hidden;
  display: inline-block;
  vertical-align: baseline;
  margin-right: 10px;
`


const SocialMediaIcon = styled.div`
  float: none;
  margin-left: 10px;
  margin-right: 10px;
  display: inline-block; 
  overflow: hidden;
  vertical-align: baseline;
  width: 24px;
  height: 24px;
`

const FooterNavigationWrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`

export const FooterNavLink = styled(Link)`
  font-size: 0.7em;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 2px;
  line-height: 20.899999618530273px;
  list-style-type: disc;
  padding-bottom: 10px;
  padding-right: 20px;
  padding-top: 10px;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;
  color: #606060;
  
  &:hover{
    color: #68A7A2;
    border-color: #68A7A2;
  }
`


export default function Footer() {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
            totalCount
            edges {
              node {
                id
                frontmatter {
                  title
                  date(formatString: "DD MMMM, YYYY")
                }
                fields {
                  slug
                }
                excerpt
              }
            }
          }
        }
      `}

      render={data => (
        <div>
          <div style={{overflow: 'auto'}}>
            <Copyright>© 2021 Dilraj Devgun</Copyright>
            <Socials>
              <SocialMediaIcon>
                <a href="https://twitter.com/merakitasks">
                  <img alt="twitter icon" src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGhlaWdodD0iMTAwJSIgc3R5bGU9ImZpbGwtcnVsZTpldmVub2RkO2NsaXAtcnVsZTpldmVub2RkO3N0cm9rZS1saW5lam9pbjpyb3VuZDtzdHJva2UtbWl0ZXJsaW1pdDoyOyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgd2lkdGg9IjEwMCUiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2VyaWY9Imh0dHA6Ly93d3cuc2VyaWYuY29tLyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxwYXRoIGQ9Ik0xNjEuMDE0LDQ2NC4wMTNjMTkzLjIwOCwwIDI5OC44ODUsLTE2MC4wNzEgMjk4Ljg4NSwtMjk4Ljg4NWMwLC00LjU0NiAwLC05LjA3MiAtMC4zMDcsLTEzLjU3OGMyMC41NTgsLTE0Ljg3MSAzOC4zMDUsLTMzLjI4MiA1Mi40MDgsLTU0LjM3NGMtMTkuMTcxLDguNDk1IC0zOS41MSwxNC4wNjUgLTYwLjMzNCwxNi41MjdjMjEuOTI0LC0xMy4xMjQgMzguMzQzLC0zMy43ODIgNDYuMTgyLC01OC4xMDJjLTIwLjYxOSwxMi4yMzUgLTQzLjE4LDIwLjg1OSAtNjYuNzAzLDI1LjQ5OGMtMTkuODYyLC0yMS4xMjEgLTQ3LjYwMiwtMzMuMTEyIC03Ni41OTMsLTMzLjExMmMtNTcuNjgyLDAgLTEwNS4xNDUsNDcuNDY0IC0xMDUuMTQ1LDEwNS4xNDRjMCw4LjAwMiAwLjkxNCwxNS45NzkgMi43MjIsMjMuNzczYy04NC40MTgsLTQuMjMxIC0xNjMuMTgsLTQ0LjE2MSAtMjE2LjQ5NCwtMTA5Ljc1MmMtMjcuNzI0LDQ3LjcyNiAtMTMuMzc5LDEwOS41NzYgMzIuNTIyLDE0MC4yMjZjLTE2LjcxNSwtMC40OTUgLTMzLjA3MSwtNS4wMDUgLTQ3LjY3NywtMTMuMTQ4bDAsMS4zMzFjMC4wMTQsNDkuODE0IDM1LjQ0Nyw5My4xMTEgODQuMjc1LDEwMi45NzRjLTE1LjQ2NCw0LjIxNyAtMzEuNjkzLDQuODMzIC00Ny40MzEsMS44MDJjMTMuNzI3LDQyLjY4NSA1My4zMTEsNzIuMTA4IDk4LjE0LDcyLjk1Yy0zNy4xOSwyOS4yMjcgLTgzLjE1Nyw0NS4xMDMgLTEzMC40NTgsNDUuMDU2Yy04LjM1OCwtMC4wMTYgLTE2LjcwOCwtMC41MjIgLTI1LjAwNiwtMS41MTZjNDguMDM0LDMwLjgyNSAxMDMuOTQsNDcuMTggMTYxLjAxNCw0Ny4xMDQiIHN0eWxlPSJmaWxsLXJ1bGU6bm9uemVybzsiLz48L3N2Zz4="></img>
                </a>
              </SocialMediaIcon>
              <SocialMediaIcon>
                <a href="https://www.instagram.com/meraki.app/">
                  <img alt="instagram icon" src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMC8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvVFIvMjAwMS9SRUMtU1ZHLTIwMDEwOTA0L0RURC9zdmcxMC5kdGQnPjxzdmcgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMTAwIDEwMCIgaGVpZ2h0PSIxMDBweCIgaWQ9IkxheWVyXzEiIHZlcnNpb249IjEuMCIgdmlld0JveD0iMCAwIDEwMCAxMDAiIHdpZHRoPSIxMDBweCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PHBhdGggZD0iTTcwLDkuOTk5SDMwYy0xMC45OTksMC0yMCw5LjAwMS0yMCwyMHY0MC4wMDJjMCwxMC45OTYsOS4wMDEsMjAsMjAsMjBoNDBjMTAuOTk5LDAsMjAtOS4wMDQsMjAtMjBWMjkuOTk5ICBDOTAsMTksODAuOTk5LDkuOTk5LDcwLDkuOTk5eiBNODMuMzMzLDcwLjAwMWMwLDcuMzUtNS45NzksMTMuMzMzLTEzLjMzMywxMy4zMzNIMzBjLTcuMzUxLDAtMTMuMzMzLTUuOTgzLTEzLjMzMy0xMy4zMzNWMjkuOTk5ICBjMC03LjM1Miw1Ljk4Mi0xMy4zMzMsMTMuMzMzLTEzLjMzM2g0MGM3LjM1NCwwLDEzLjMzMyw1Ljk4MSwxMy4zMzMsMTMuMzMzVjcwLjAwMXoiLz48Y2lyY2xlIGN4PSI3MS42NjciIGN5PSIyOC4zMzIiIHI9IjUiLz48cGF0aCBkPSJNNTAsMjkuOTk5Yy0xMS4wNDgsMC0yMCw4Ljk1My0yMCwyMGMwLDExLjA0Myw4Ljk1MiwyMC4wMDIsMjAsMjAuMDAyYzExLjA0NSwwLDIwLTguOTU5LDIwLTIwLjAwMiAgQzcwLDM4Ljk1Miw2MS4wNDUsMjkuOTk5LDUwLDI5Ljk5OXogTTUwLDYzLjMzNGMtNy4zNjMsMC0xMy4zMzMtNS45Ny0xMy4zMzMtMTMuMzM1UzQyLjYzNywzNi42NjYsNTAsMzYuNjY2ICBzMTMuMzMzLDUuOTY4LDEzLjMzMywxMy4zMzNTNTcuMzYzLDYzLjMzNCw1MCw2My4zMzR6Ii8+PC9zdmc+"></img>
                </a>
              </SocialMediaIcon>
            </Socials>
          </div>
          <FooterNavigationWrapper>
            {data.allMarkdownRemark.edges.map(({ node }) => (
              <FooterNavLink to={node.fields.slug}>{node.frontmatter.title}</FooterNavLink>
            ))}
          </FooterNavigationWrapper>
        </div>
      )}
    />
  )
}